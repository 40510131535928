import React, {useEffect, useState} from "react";

export default () => {
  const [list, setList] = useState<{link: string; text: string;}[]>();
  useEffect(() => {
    fetch('/config/list').then(res => res.json()).then(json => {
      setList(json);
    })
  }, [])
  return (
    <>
      {
        list && list.map(item => {
          return (
              <a href={item.link} style={{color: 'red', paddingLeft: '5px'}}>{item.text}</a>
          )
        })
      }
      {
        !list && (
          <>
            <a href={"https://z.wiki/jsbin/tikiviwute"} style={{color: 'red'}}>使用须知</a> &nbsp;
            <a href={"https://z.wiki/jsbin/waleyijila"} style={{}}>求支持</a>
          </>
        )
      }
    </>
  )
}