import axios from "axios";
import {v4 as uuidv4} from "uuid";

export const getId = () => {
  if (!localStorage.getItem('fdd_uid')) {
    const id = uuidv4();
    console.info('uuid is', id, typeof id);
    localStorage.setItem('fdd_uid', id.replace(/-/g, ''));
  }
  return localStorage.getItem('fdd_uid');
}

/**
 * 上传文件
 * @param file
 * @param onUploadProgress
 */
export const postFile = (file: any, onUploadProgress: (p: number) => void) => {
  const reader = new FileReader(); // 创建读取文件对象
  reader.readAsDataURL(file); // 发起异步请求，读取文件
  reader.onload = function () {  // 文件读取完成后
    // 读取完成后，将结果赋值给img的src
    // TODO: fudongdong 2023/7/31 21:36
  };
  const count = Number(localStorage.getItem('count')) || 0;
  const newCount = (count + 1) + '';
  localStorage.setItem('count', newCount)

  const formData = new FormData();  // 创建一个formdata对象

  formData.append('file', file);
  formData.append('count', newCount);
  formData.append('fileName', file.name);
  formData.append('uid', getId() as any);
  return axios.request({
    method: 'post',
    url: '/img/upload',
    data: formData,
    onUploadProgress: (p) => {
      const percent = Math.floor(p.loaded / (p.total || 0) * 100);
      console.info('percent is', percent);
      onUploadProgress && onUploadProgress(percent);
    }
  }).then(res => {
    console.log("upload.then");
    const record = res.data;
    const url = record.url;
    console.info('url is ', url);
    if ((res as any).success == false) {
      alert((res as any).message);
    }
  }).catch(error => {
    console.warn("upload.catch", error);
    const res = error.response.data;
    if ((res as any).success == false) {
      alert((res as any).message);
    }
  })
}
