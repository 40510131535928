import React, {useEffect, useState} from 'react';
import {Dropdown, Input, MenuProps, message, Modal, Space} from 'antd';
import Config from "./Config";
import Cart from "./Cart";

const Login: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('')
  const [showConfig, setShowConfig] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const handleLogin = (e: any) => {
    e.preventDefault()
    console.info('handleLogin')
  }

  const [userInfo, setUserInfo] = useState<Record<string, string>>({})

  useEffect(() => {
    fetch('/login/info', {
      method: 'GET',
    }).then(res => res.json()).then((e) => {
      console.info(e)
      if (e.isSuccess) {
        setUserInfo(e)
      }
    })
  }, [])

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (email.trim() == '') {
      message.error('请输入邮箱')
      return
    }

    // 发送登录链接
    fetch('/login/email', {
      method: 'POST',
      body: JSON.stringify({
        email: email.trim()
      }),
      headers: {
        'Content-Type': 'application/json;charset=utf8'
      }
    }).then(res => res.json()).then((e) => {
      console.info(e)
      if (e.isSuccess) {
        message.info(e.message || '请查收邮箱进行登录')
        setIsModalOpen(false)
      } else {
        message.error(e.message || '发送失败，请稍后再试')
      }
    })
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items: MenuProps['items'] = [
    {
      key: '0',
      label: (
        "设置"
      ),
      onClick: (e) => {
        setShowConfig(true);
      }
    },
    {
      key: '1',
      label: (
        "购买VIP"
      ),
      onClick: (e) => {
        setShowCart(true);
      }
    },
    {
      key: '999',
      label: (
        "退出"
      ),
      onClick: (e) => {
        fetch('/login/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf8'
          }
        }).then(res => res.json()).then((e) => {
          setUserInfo({});
          message.success(e.message || "您已成功退出")
        })
      }
    },
  ];

  return (
    <>
      {
        userInfo && userInfo.email && (
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                  <span style={{
                    "height": "20px",
                    "fontFamily": "PingFangSC",
                    "fontWeight": 400,
                    "fontSize": "12px",
                    "color": "rgb(76, 83, 98)",
                    "lineHeight": "20px"
                  }}>{userInfo.email}</span>
              </Space>
            </a>
          </Dropdown>
        )
      }

      {
        !userInfo?.email && (
          <a
            onClick={showModal}
            style={{
              "height": "20px",
              "fontFamily": "PingFangSC",
              textDecoration: 'none',
              cursor: 'pointer',
              "fontWeight": 400,
              "fontSize": "12px",
              "color": "#4C5362",
              "lineHeight": "20px"
            }}
          >
            登录
          </a>
        )
      }
      <Modal title="登录" open={isModalOpen} onCancel={handleCancel} cancelText={"取消"} okText={"发送登录链接"} onOk={handleOk}>
        <Space.Compact style={{ width: '100%' }}>
          <Input defaultValue="请输入邮箱" placeholder={"请输入邮箱"} value={email} onChange={e => setEmail(e.target.value)} type={'email'} />
        </Space.Compact>
      </Modal>

      <Modal title="设置中心" footer={null} open={showConfig} okText={"关闭"} cancelText={"关闭"}
             onCancel={() => setShowConfig(false)} onOk={() => {
        setShowConfig(false)
      }}>
        <Config/>
      </Modal>

      <Modal title="VIP 中心" footer={null} width={"80%"} open={showCart} okText={"关闭"} cancelText={"关闭"}
             onCancel={() => setShowCart(false)} onOk={() => {
        setShowCart(false)
      }}>
        <Cart/>
      </Modal>
    </>
  );
};

export default Login;