import './index.less'
import Login from "./Login";

/**
 *
 * @author dongdong.fdd
 * @Company AutoNavi Co.,Ltd.
 * @date 2023/7/29 15:19
 */
function Header() {


  return (
    <div
      className="Header"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px',
        height: '56px',
        background: 'white',
        alignItems: 'center',
      }}
    >
      <div
        className="left"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img src={"https://z.wiki/favicon.ico"} width={24} height={24} title={"敖武的图床"} alt={"敖武的图床"} />
        <span
          style={{
            paddingLeft: '10px',
            "height": "20px",
            "fontFamily": "PingFangSC",
            "fontWeight": 400,
            "fontSize": "12px",
            "color": "#4C5362",
            "lineHeight": "20px"
          }}
        >
          敖武的图床
        </span>
      </div>
      <div className="right">
        <Login />
      </div>
    </div>
  );
}

Header.propTypes = {}

export default Header
