import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, Input, message} from "antd";
import {WarningOutlined} from "@ant-design/icons";

const Config: React.FC = () => {
  const [config, setConfig] = useState<Record<string, string>>({});

  useEffect(() => {
    fetch('/login/info', {
      method: 'GET',
    }).then(res => res.json()).then((e) => {
      console.info(e)
      if (e.isSuccess) {
        setConfig(JSON.parse(e.detail?.config || '{}'));
      }
    })
  }, [])

  const [saving, setSaving] = useState(false)

  const handleSave = () => {
    console.info('handleSave', config)
    setSaving(true);
    fetch('/admin/config/update', {
      method: 'POST',
      body: JSON.stringify({
        ...config
      }),
      headers: {
        'Content-Type': 'application/json;charset=utf8'
      }
    }).then(res => res.json()).then((data) => {
      console.info('flag is', data);
      if (data.success) {
        message.info('配置已更新')
      } else {
        message.error("配置更新失败")
      }
    }).finally(() => {
      setSaving(false);
    })
  }

  return (
    <>
      <Form>
        <Alert message="仅支持VIP用户" icon={<WarningOutlined/>} type="error" style={{marginBottom: '8px'}} showIcon/>
        <Form.Item label="水印内容">
          <Input placeholder="请输入水印内容，最多6个字符串" value={config.watermask}
                 onChange={(e) => setConfig({...config, watermask: e.target.value.slice(0, 12)})}/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={saving} onClick={handleSave}>保存</Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Config;